.m_informationDetailHeading
  text-align left
  padding-bottom 22px
  line-height 1.4
  .info
    margin 20px 0 0
    font-size 0
    +mediaSp()
      margin 15px 0 0
    .date
      display inline-block
      width 120px
      margin 0 0 15px
      color text02
      font-size 1.6rem
      vertical-align middle
      +mediaSp()
        display block
        width auto
        font-size 1.4rem
        margin 0 0 10px
    .tag
      display inline-block
      vertical-align middle
      +mediaSp()
        li
          font-size 1rem
          line-height 1

      
.m_informationDetailContent
  padding 70px 0 90px
  +mediaSp()
    padding 35px 0 45px
  p
    font-size 1.8rem
    line-height 2
    margin 0 0 50px
    +mediaSp()
      font-size 1.6rem
      margin 0 0 25px
  .bg01
    background #f4faeb
    padding 30px 40px
    margin 0 0 60px
    +mediaSp()
      padding 15px 20px
      margin 0 0 30px
  .imgList
    margin 0 0 30px
    clearfix()
    li
      width 340px
      margin 0 0 30px 29px
      float left
      +mediaSp()
        width 80%
        margin 0 auto
        float none
        + li
          margin-top 20px
      +mediaPc()
        &:nth-child(3n+1)
          margin-left 0
      .img
        width 100%
        margin 0 0 20px
        +mediaSp()
          margin 0 0 5px
        img
          width 100%
  .m_commonBtn03--back
    padding 42px 0 0
    text-align center
    +mediaSp()
      padding 30px 0 0