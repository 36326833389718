// hx要素のタイトルのデザインを記述
// 設置個所によりレイアウトが異なる場合は、ページ下部のタイトルレイアウトに調整記述を書くこと
// ==========================================================================
// タイトルデザイン
// ==========================================================================
.m_headingArea01
  border-bottom 1px solid line01
  padding 42px 0
  font-size 4.4rem
  text-align center
  &--noBorder
    border-bottom none
  +mediaSp()
    font-size 2.1rem
    padding 27px 0
  h1
    font-weight normal
    +mediaSp()
      font-weight bold

.m_contentTitle01
  background themeLightGray01
  padding 75px 0 55px
  text-align center
  +mediaSp()
    padding 40px 0 35px
  h2
    padding 0 0 15px
    font-size 3.6rem
    font-weight normal
    position relative
    letter-spacing 0.1em
    +mediaSp()
      padding 0 0 10px
      font-size 1.8rem
      font-weight bold
    &:after
      content ""
      display block
      background themeMainColor01
      width 40px
      height 4px
      position absolute
      bottom 0
      right 0
      left 0
      margin auto

.m_subTitle01
  text-align center
  font-size 3.2rem
  font-weight normal
  letter-spacing 0.1em
  line-height 1.25
  +mediaSp()
    font-size 1.6rem
    font-weight bold
  span
    display block
    color themeMainColor01
    font-size 1.6rem
    +mediaSp()
      margin 10px 0 0
      font-size 1.2rem
      font-weight normal
  &--hasIcon
    padding 80px 0 0
    background-size 60px
    background-repeat no-repeat
    background-position center top
    +mediaSp()
      padding-top 75px
    &.support
      background-image url(image_path + "/common/icon_note.png")
    &.visitCare
      background-image url(image_path + "/common/icon_home.png")
    &.dayService
      background-image url(image_path + "/common/icon_truck.png")

.m_blockTitle01
  padding 0 0 20px
  font-size 2.8rem
  line-height 1.25
  letter-spacing 0.1em
  border-bottom solid 1px line01
  +mediaSp()
    padding 0 0 15px
    font-size 1.8rem
    font-weight bold
  &--hasIcon
    padding-left 65px
    position relative
    +mediaSp()
      padding-left 32px
    &:before
      content ""
      display block
      position absolute
      top 0
      bottom 20px
      left 0
      margin auto
    &.greeting
      &:before
        background url(image_path + "/common/icon_message.png") no-repeat
        background-size 100%
        width 48px
        height 47px
        +mediaSp()
          width 24px
          height 24px

.m_blockTitle02
  padding 5px 20px
  font-size 2.8rem
  line-height 1.25
  letter-spacing 0.2em
  border-left solid 4px themeMainColor01
  font-weight normal
  margin-top 80px
  margin-bottom 30px
  +mediaSp()
    padding: 5px 0 5px 15px
    letter-spacing 0.1em
    font-size 1.8rem
    font-weight bold
    margin-top 40px
    margin-bottom 15px
    word-break normal
    word-wrap break-word

.m_blockTitle03
  color themeMainColor01
  font-size 2.4rem
  margin 40px  0 20px
  +mediaSp()
    font-size 1.8rem

// ==========================================================================
// タイトルレイアウト
// ==========================================================================
