.faq
  dl
    margin-bottom 40px
    +mediaSp()
      margin-bottom 20px
    dt
      background url(image_path + '/common/icon_faq.png') left center no-repeat
      font-size 2.4rem
      border-top 4px solid line01
      padding 40px 20px 40px 80px
      word-break normal
      word-wrap break-word
      +mediaSp()
        background url(image_path + '/common/icon_faq.png') 10px 22px no-repeat
        background-size 30px
        font-size 1.8rem
        padding 20px 20px 20px 50px
        line-height 1.4
    dd
      border-top 1px solid line01
      padding 20px
      text-align justify
      word-break normal
      word-wrap break-word
      a
        text-decoration underline
        &.linkIcon
          padding-right 12px
          background url(image_path +'/common/arrow_left02.png') right center no-repeat
          background-size 5px 9px