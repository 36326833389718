// 頻繁に使うリスト形状の要素を記述
// リストの中に汎用的な箱状のレイアウトがある場合は基本box側に記述し、本stylでは背景色や間隔等の調整レベルに留めること
//
.m_informationList
  border-top 1px solid line01
  li
    border-bottom 1px solid line01
    a
      padding 20px 40px 20px 0
      display flex
      justify-content: flex-start
      align-items flex-start
      text-decoration none
      position relative
      transition .4s
      +mediaSp()
        padding 15px 20px 15px 0
      &:hover
        +mediaPc()
          background rgba(themeMainColor01, 0.1)
          transition .4s
          p.text
            text-decoration underline
      &::after
        content ""
        width 15px
        height 27px
        background url(image_path + '/common/arrow_left01.png') left top no-repeat
        background-size 15px 27px
        position absolute
        top 50%
        right 10px
        margin-top -14px
      +mediaSp()
        display block
        &::after
          background url(image_path + '/common/arrow_left02.png') left top no-repeat
          background-size 10px auto
          width 10px
          height 19px
          right 0
      .date
        width 130px
        padding 0 15px
        color text02
        +mediaSp()
          width 100%
          padding 0
          font-size 1.2rem
          margin-bottom 5px
      .contents
        flex 1
        ul.tag
          li
            border-bottom 0
            +mediaSp()
              font-size 1rem
              line-height 1
        p.text
          display inline-block
          font-size 1.8rem
          +mediaSp()
            font-size 1.4rem
      &.blankLink
        p.text::after
          content ""
          width 20px
          height 18px
          display inline-block
          background url(image_path + '/common/icon_blank.png') left top no-repeat
          background-size 20px 18px
          vertical-align -2px
          margin-left 5px

.m_sitemapList
  +mediaPc()
    width: 50%
    display: inline-block
    vertical-align: top
    margin-top: 80px
  +mediaSp()
    margin-top: 40px
  &.right
    +mediaPc()
      width: 45%
      padding-left: 4%
      border-left: 1px solid themeLightGray02
  li
    background url(image_path + '/common/arrow_left01.png') left 8px no-repeat
    padding-left: 30px
    margin-bottom: 80px
    font-size: 2.4rem
    +mediaSp()
      margin-bottom: 40px
      background url(image_path + '/common/arrow_left02.png') left 8px no-repeat
      background-size: 10px
      padding-left: 20px
      margin-bottom: 40px
      font-size: 1.8rem
    ul
      margin-top: 20px
      margin-left: 40px
      +mediaSp()
        margin-top: 15px
        margin-left: 10px
      li
        background url(image_path + '/common/arrow_inner01.png') left 8px no-repeat
        padding-left: 20px
        font-size: 1.8rem
        margin-bottom: 20px
        +mediaSp()
          background url(image_path + '/common/arrow_inner02.png') left 8px no-repeat
          background-size: 10px
          font-size: 1.6rem

.m_LinkList01
  display flex
  padding 25px 0 0
  justify-content space-between
  +mediaSp()
    display block
    padding 0
  li
    width 360px
    display table
    +mediaSp()
      display table
      width 100%
      padding 10px 0
      position relative
      border-bottom solid 1px line01
    .img
      width 150px
      display table-cell
      +mediaSp()
        width 75px
        vertical-align middle
      img
        width 100%
    .txtArea
      width 210px
      display table-cell
      padding 20px 0 0 15px
      position relative
      vertical-align top
      +mediaSp()
        width auto
        padding 0 0 0 20px
        vertical-align middle
      p
        line-height 1.4
        font-size 1.6rem
        font-weight bold
        +mediaSp()
          font-weight normal
      .m_commonBtn03
        position absolute
        left 15px
        top 90px
        +mediaSp()
          margin 0
          top 0
          right 0
          left 0
          bottom 0
          margin auto
          a
            display block
            border none
            height 100%
            text-indent -9999px
            border-radius 0
            &:before
              background-image url(image_path + "/common/arrow_left02.png")
              width 10px
              height 19px
              right 0

.m_LinkList02
  display flex
  flex-wrap wrap
  margin-top 25px
  +mediaSp()
    margin-top 15px
  li
    +mediaPc()
      width calc((100% - 40px) / 3)
      margin-right 20px
    &:nth-child(3n)
      margin-right 0
    &:nth-child(n+4)
      +mediaPc()
        margin-top 20px
    + li
      +mediaSp()
        margin-top 10px
    img
      width 100%

.m_anchorList01
  ul
    font-size 0
    padding 90px 0 80px
    text-align center
    +mediaSp()
      padding 35px 0 20px
      text-align left
    li
      display inline-block
      padding 0 8px
      +mediaSp()
        padding 0 15px 15px 0

.m_listStyle01
  li
    padding-left 20px
    margin-bottom 10px
    position relative
    &:before
      position absolute
      top 12px
      left 0
      content ""
      background url(image_path + "/common/icon_list.svg") top left no-repeat
      background-size 5px
      width 5px
      height 5px
      +mediaSp()
        top 10px