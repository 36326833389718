/* ==========================================================================*/
/* reset*/
/* ==========================================================================*/
/*html5doctor.com Reset Stylesheet
 *v1.4
 *2009-07-27
 *Author: Richard Clark - http://richclarkdesign.com*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video
  margin 0
  padding 0
  border 0
  outline 0
  font-size 100%
  vertical-align bottom
  background transparent
  list-style-type none
body
  line-height 1
  word-wrap break-word
  word-break break-all
article, aside, dialog, figure, footer, header,
hgroup, nav, section
  display block
nav ul
  list-style none
blockquote, q
  quotes none
blockquote:before, blockquote:after,
q:before, q:after
  content ''
  content none
a
  margin 0
  padding 0
  border 0
  font-size 100%
  vertical-align baseline
  background transparent
ins
  background-color #ff9
  color #000
  text-decoration none
mark
  background-color #ff9
  color #000
  font-style italic
  font-weight bold
del
  text-decoration line-through
abbr[title], dfn[title]
  border-bottom 1px dotted #000
  cursor help
table
  border-collapse collapse
  border-spacing 0
hr
  display block
  height 1px
  border 0
  border-top 1px solid #cccccc
  margin 1em 0
  padding 0
input, select
  vertical-align middle


/* ==========================================================================*/
/* base*/
/* ==========================================================================*/
*
  box-sizing border-box

html
  width 100%
  font-size 62.5%
  background #fff

body
  width 100%
  font-size 1.6rem
  background-color #fff
  line-height 1.75
  -webkit-text-size-adjust 100%
  gothic()
  color text01
  +mediaSp()
    font-size 1.4rem
    background-color transparent

a
  color text01
  text-decoration none

a:hover
  text-decoration underline
