.about
  .m_imgLeftBox
    .text
      word-break normal
      word-wrap break-word
      .boldText
        font-weight bold
        color themeMainColor01
        font-size 2.4rem
        +mediaSp()
          font-size 1.8rem
  +mediaPc()
    .m_imgLeftBox
      .text
        font-size 1.8rem
        word-break normal
        word-wrap break-word
  h3.aboutTitle01
    border-left 4px solid themeSubColor01
    font-size 3.2rem
    padding 0 0 0 20px
    margin 80px 0 40px
    +mediaPc()
      font-weight normal
      letter-spacing 0.1em
    +mediaSp()
      font-size 1.8rem
      margin 40px 0 20px
  h3.aboutTitle02
    border-left 4px solid themeMainColor01
    line-height 1
    font-size 3.2rem
    padding 0 0 0 20px
    margin 80px 0 40px
    +mediaPc()
      font-weight normal
      letter-spacing 0.1em
    span
      font-size 1.8rem
    +mediaSp()
      font-size 1.8rem
      margin 40px 0 20px
      line-height 1.2
      span
        font-size 1.2rem
        font-weight normal

  .serviceFlowStep
    li
      display table
      width 100%
      height 120px
      border 1px solid themeSubColor01
      margin-bottom 50px
      position relative
      +mediaSp()
        display block
        height auto
      &:not(.step4):after
        content ""
        display block
        width 0
        height 0
        border-style solid
        border-width 40px 60px 0 60px
        border-color lighten(themeSubColor01, 50%) transparent transparent transparent
        position absolute
        bottom -41px
        left 50%
        margin-left -60px
      &.step1
        background url(image_path + '/about/img_flow01.png') right center no-repeat
      &.step2
        background url(image_path + '/about/img_flow02.png') right center no-repeat
      &.step3
        background url(image_path + '/about/img_flow03.png') right center no-repeat
      &.step4
        background url(image_path + '/about/img_flow04.png') right center no-repeat
      +mediaSp()
        &.step1
          background url(image_path + '/about/img_flow01_sp.png') center bottom no-repeat
          background-size 310px
        &.step2
          background url(image_path + '/about/img_flow02_sp.png') center bottom no-repeat
          background-size 310px
        &.step3
          background url(image_path + '/about/img_flow03_sp.png') center bottom no-repeat
          background-size 310px
        &.step4
          background url(image_path + '/about/img_flow04_sp.png') center bottom no-repeat
          background-size 310px
      span
        display table-cell
        vertical-align middle
        &.step
          background themeSubColor01
          color themeWhite
          text-align center
          width 120px
          font-size 2rem
          letter-spacing 0.1em
          +mediaSp()
            width 25%
            height 60px
            line-height 60px
            font-size 1.4rem
            display inline-block
        &.title
          background lighten(themeSubColor01, 85%)
          color themeSubColor01
          text-align center
          width 210px
          font-size 2rem
          font-weight bold
          +mediaSp()
            font-size 1.8rem
            width 75%
            height 60px
            line-height 60px
            display inline-block
        &.description
          padding 0 300px 0 30px
          word-break normal
          word-wrap break-word
          span
            font-weight bold
            font-size 1.8rem
          +mediaSp()
            padding 15px 15px 120px
            display block
            span
              font-size 1.6rem

  .supportLinkBoxTitle
    background themeSubColor01
    color themeWhite
    height 80px
    line-height 80px
    font-size 3.2rem
    text-align center
    +mediaSp()
      height 40px
      line-height 40px
      font-size 1.8rem
  .supportLinkBox
    background themeMainColor01 + 80%
    text-align center
    padding 40px
    font-weight bold
    +mediaSp()
      padding 15px
    .m_commonBtn02
      margin 30px 0
      +mediaSp()
        margin 15px 0
    .captionText
      color themeDarkGray01
      font-size 1.6rem
      text-align left
      font-weight normal
      +mediaSp()
        font-size 1.2rem
      li
        padding-left 2em
        text-indent -2em
        span
          display inline-block
          width 2em
          text-indent 0

  &--secondBlock
    border-top 1px solid line01
    +mediaPc()
      border-bottom 1px solid line01
      margin-bottom 80px
    +mediaSp()
      margin-bottom 60px
    .inBox
      padding 35px 22px
      +mediaSp()
        padding 20px 15px 30px
        border-bottom 1px solid line01 !important
        .img
          width 40%
          display table-cell
          margin-bottom 15px
          img
            width 100%
      .title
        .m_subTitle01
          text-align left
          +mediaPc()
            line-height 60px
            font-size 2.4rem
            margin-top 15px
            span
              display inline-block
              margin-left 20px
        +mediaSp()
          width 60%
          display table-cell
          vertical-align middle
          padding-left 15px
          font-size 2rem
      > p
        margin-top 15px
        span.caption
          font-size 1.4rem
          color themeDarkGray01
          +mediaSp()
            font-size 1.2rem

      .btnArea
        text-align center
        margin-top 30px
        +mediaSp()
          margin-top 20px
          .m_commonBtn03 a
            display block