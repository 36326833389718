// 頻繁に使う箱状の要素を記述
// ただし、中身を含めて1つのパーツとして運用する場合は、別ファイルでモジュール化すること
// こちらに記述するのはあくまで汎用的な要素の枠デザインのみ
// 設置個所によりレイアウトが異なる場合は、ページ下部のボックスレイアウトに調整記述を書くこと
// ==========================================================================
// ボックスデザイン
// ==========================================================================
.m_inquiryBox01
  background themeLightGray01
  text-align center
  pt-h(32px)
  pb-h(60px)
  p.inquiryTitle
    color themeMainColor01
    font-size 3.6rem
    margin-bottom 0
    +mediaSp()
      font-weight bold
      font-size 2.2rem
  p
    margin-bottom 10px
    +mediaSp()
      font-size 1.2rem
      color themeDarkGray01
      letter-spacing .06rem
  .inquiryInfo
    margin-top 40px
    +mediaSp()
      margin-top 20px
    .m_commonBtn01, .m_telephoneBox01
      padding 0 40px
      +mediaSp()
        padding 0
    .m_commonBtn01
      border-right 1px solid line01
      +mediaSp()
        margin-bottom 20px
        border-right 0
        a
          font-size 1.8rem
          &::before
            background-image url(image_path + '/common/icon_mail_sp.png')
            background-size 26px 20px
            width 26px
            height 20px
            vertical-align -2px
    .m_telephoneBox01
      display inline-block
      text-align left
      vertical-align middle
      +mediaSp()
        text-align center
        display inline-block
      a.telNumber
        display inline-block
        background url(image_path + '/common/icon_tell.png') left center no-repeat
        padding 10px 0 10px 55px
        img.imgSp
          display none
        +mediaSp()
          background-size 31px 32px
          padding-left 40px
          background-image url(image_path + '/common/icon_tell_sp.png')
          img.imgPc
            display none
          img.imgSp
            display inline-block
            width 227px
            vertical-align middle
      p
        padding-left 55px
        color text02
        margin-bottom 0
        +mediaSp()
          text-align left
          padding-left 6px
          font-size 1.2rem
          letter-spacing 1px


.m_columnBox01
  display flex
  +mediaSp()
    padding 0
  .inBox
    border-left solid 1px line01
    +mediaPc()
      &:last-child
        border-right solid 1px line01
    +mediaSp()
      &:first-child
        border-left none
  &--twoColumn
    .inBox
      width 50%
  &--threeColumn
    .inBox
      width 33.333%
    +mediaSp()
      display block
      .inBox
        width 100%
        border none !important

.m_baseListBox01
  margin 0 0 40px
  border solid 1px #ccc
  position relative
  +mediaSp()
    margin 0 0 20px
    border-color themeMainColor01
  + .m_baseListBox01
    margin-top 60px
    +mediaSp()
      margin-top 20px
  .boxHeader
    padding 27px 190px 20px 27px
    border-top solid 8px themeMainColor01
    +mediaSp()
      padding 22px 30px 12px 15px
      position relative
      border-top-width 4px
    .tag
      +mediaSp()
        li
          font-size 1rem
          line-height 10px
    .title
      display inline-block
      text-decoration underline
      font-size 2.8rem
      font-weight normal
      letter-spacing 0.01em
      position relative
      +mediaPc()
        &:hover
          text-decoration none
      +mediaSp()
        font-size 1.8rem
        font-weight bold
        position static
      a
        display block
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        margin auto
        +mediaSp()
          &:after
            content ""
            display block
            background url(image_path + "/common/arrow_left02.png") no-repeat
            background-size 100%
            width 10px
            height 19px
            position absolute
            top 0
            right 15px
            bottom 0
            margin auto
  .boxBody
    width 100%
    +mediaSp()
      display block
      padding 14px
      border-top solid 1px #ccc
      tbody
        display block
    tr
      border-top solid 1px #ccc
      +mediaSp()
        display block
        border-top none
        + tr
          margin 20px 0 0
      th
      td
        padding 15px 20px
        vertical-align middle
        +mediaSp()
          display block
      th
        background themeLightGray01
        width 160px
        padding 20px 0
        font-size 1.4rem
        +mediaSp()
          width 100%
          padding 5px 0
          font-size 1.2rem
      td
        +mediaSp()
          padding 10px 10px 0
        .midashi
          display block
          margin 10px 0 5px
          font-size 1.4rem
          font-weight bold
          color themeMainColor01
          &:first-child
            margin-top 0
          +mediaSp()
            margin 10px 0 0
            font-size 1.2rem
  .btnArea
    position absolute
    top 28px
    right 30px
    margin auto
    +mediaSp()
      position static
      padding 15px 7px
      border-top solid 1px #ccc
      text-align center
      clearfix()
    .m_commonBtn04
      +mediaSp()
        float left
        width calc(50% - 7px)
        a
          width 100%
          min-width 1px
      + .m_commonBtn04
        margin 15px 0 0
        +mediaSp()
          margin 0
          float right


.m_baseLinkBox01
  .m_contentTitle01
    background none
  .l_commonWrap02
    border-top solid 1px #ccc
    border-bottom solid 1px #ccc
    .baseList
      clearfix()
      border-right solid 1px #ccc
      +mediaSp()
        padding 0
        border-right none
      li
        display table
        width 25%
        height 115px
        float left
        position relative
        border-left solid 1px #ccc
        +mediaSp()
          width 50%
          height 60px
          &:nth-child(odd)
            float left
            border-left none
          &:nth-child(even)
            float right
          &:nth-child(n+3)
            border-top solid 1px #ccc
        a
          display table-cell
          padding 0 20px 0 0
          line-height 1.4
          letter-spacing .1em
          text-align center
          text-decoration none
          font-size 2.4rem
          vertical-align middle
          box-sizing border-box
          +mediaSp()
            letter-spacing 0
            font-size 1.6rem
          &:after
            content ""
            display block
            background url(image_path + "/common/arrow_left01.png")
            background-size 100%
            width 15px
            height 27px
            position absolute
            top 0
            bottom 0
            right 20px
            margin auto
            +mediaSp()
              width 10px
              height 19px
              right 15px
          span
            display block
            letter-spacing .01em
            font-size 1.6rem
            +mediaSp()
              font-size 1.2rem

.m_imgLeftBox
  display table
  width 100%
  +mediaPc()
    .img
      padding-right 40px
      display table-cell
      vertical-align middle
    .text
      display table-cell
      vertical-align middle
      width 100%
  +mediaSp()
    display block
    .img
      margin-bottom 15px
      text-align center
      img
        max-width 100%
.m_imgRightBox
  display table
  width 100%
  +mediaPc()
    .img
      padding-left 40px
      display table-cell
      vertical-align middle
    .text
      display table-cell
      vertical-align middle
      width 100%
  +mediaSp()
    display block
    .img
      margin-bottom 15px
      text-align center
      img
        max-width 100%


// ==========================================================================
// ボックスレイアウト
// ==========================================================================
