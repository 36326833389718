// ボタン状のくデザインを記述
// テキストリンクデザインは_module_common_linkの方に記述すること
// 設置個所によりレイアウトが異なる場合は、ページ下部のテキストリンクレイアウトに調整記述を書くこと
// ==========================================================================
// ボタンデザイン
// ==========================================================================
.m_commonBtn01
  display inline-block
  vertical-align middle
  a
    display inline-block
    background themeMainColor01
    border-radius 40px
    padding 22px 45px
    color themeWhite
    font-weight bold
    font-size 2rem
    text-decoration none
    letter-spacing 1px
    &:hover
      +mediaPc()
        transform translateY(4px)
        transition .4s
    +mediaSp()
      font-size 1.4rem
      padding 10px 20px
  &--inquiry
    +mediaSp()
      width 100%
    a
      &::before
        content: ""
        display inline-block
        vertical-align -4px
        margin-right 10px
        width 32px
        height 24px
        background url(image_path + '/common/icon_mail.png') left top no-repeat
        background-size 32px 24px
      +mediaSp()
        width 100%
        &::before
          width 16px
          height 12px
          background-size 16px 12px
          vertical-align 0
  &--color02
    a
      background themeSubColor01
  &--document
    a
      font-size 2rem
      +mediaSp()
        font-size 1.8rem
      &::before
        content: ""
        display inline-block
        vertical-align middle
        margin-right 10px
        width 34px
        height 26px
        background url(image_path + '/common/ico_document.png') left top no-repeat
        background-size 100%
        +mediaSp()
          width 26px
          height 24px

.m_commonBtn02
  text-align center
  + .m_commonBtn02
    margin-top  20px
  a
    display inline-block
    background themeMainColor01
    width 300px
    height 60px
    line-height 60px
    text-align center
    font-size 2rem
    font-weight bold
    letter-spacing 0.01em
    color themeWhite
    border-radius 30px
    position relative
    text-decoration none
    +mediaPc()
      &:hover
        transition .2s
    +mediaSp()
      width 100%
      height 40px
      line-height 40px
      font-size 1.4rem
    &:before
      content ""
      display block
      background url(image_path + '/common/arrow_left01_white.png') left top no-repeat
      background-size 100%
      width 9px
      height 17px
      position absolute
      top 0
      bottom 0
      right 20px
      margin auto
      +mediaSp()
        right 15px
        width 8px
        height 14px
    &:hover
      +mediaPc()
        transform translateY(4px)
        transition .4s
  &--color02
    a
      background themeSubColor01
  &--fs18-14
    a
      font-size 1.8rem
      +mediaSp()
        font-size 1.4rem

.m_commonBtn03
  a
    display inline-block
    height 40px
    line-height 36px
    padding 0 40px 0 25px
    text-align center
    text-decoration none
    color themeMainColor01
    font-size 1.6rem
    font-weight bold
    position relative
    border solid 2px themeMainColor01
    border-radius 20px
    +mediaPc()
      &:hover
        transition .2s
    &:before
      content ""
      display block
      background url(image_path + '/common/arrow_left01.png') left top no-repeat
      background-size 100%
      width 8px
      height 14px
      position absolute
      top 0
      bottom 0
      right 14px
      margin auto
    &:hover
      +mediaPc()
        transform translateY(4px)
        transition .4s
  &--anchor
    a
      min-width 125px
      font-size 1.8rem
      border-width 1px
      line-height 38px
      +mediaSp()
        min-width 1px
        padding 0 40px 0 25px
        font-size 1.4rem
      &:before
        background url(image_path + '/common/arrow_bottom01.png') center center no-repeat
        background-size 100%
        width 18px
        height 10px
        +mediaSp()
          width 14px
          height 8px
  &--back
    a
      min-width 240px
      padding 0
      color themeDarkGray01
      border-color themeDarkGray01
      &:before
        display none


.m_commonBtn04
  a
    display inline-block
    background themeMainColor01
    width 160px
    height 40px
    line-height 40px
    font-weight bold
    text-decoration none
    text-align center
    color themeWhite
    border-radius 20px
    +mediaSp()
      font-size 1.6rem
    &:hover
      +mediaPc()
        transform translateY(4px)
        transition .4s
  &--color02
    a
      background themeSubColor01
  &--document
    a
      padding-left 22px
      position relative
      &:before
        content ""
        display block
        background url(image_path + '/common/ico_document.png') center center no-repeat
        background-size 100%
        width 25px
        height 19px
        position absolute
        top 0
        bottom 0
        left 25px
        margin auto

.m_bannerBtn01
  a
    display block
    background themeMainColor01
    height 60px
    line-height 60px
    text-align center
    border-radius 30px
    letter-spacing 0.01em
    color themeWhite
    text-decoration none
    font-size 2.4rem
    font-weight bold
    position relative
    +mediaPc()
      &:hover
        transition .2s
    +mediaSp()
      height 50px
      line-height 50px
      font-size 1.8rem
    &:before
      content ""
      display block
      background url(image_path + "/common/icon_blank_white.png") no-repeat
      background-size 100%
      width 20px
      height 20px
      position absolute
      top 0
      bottom 0
      right 30px
      margin auto
      +mediaSp()
        width 18px
        height 18px
        right 20px
    &:hover
      +mediaPc()
        transform translateY(4px)
        transition .4s
