@import 'modules/_module_common_banner'
@import 'modules/_module_common_btn'
@import 'modules/_module_common_box'
@import 'modules/_module_common_link'
@import 'modules/_module_common_list'
@import 'modules/_module_common_tab'
@import 'modules/_module_common_text'
@import 'modules/_module_common_title'
@import 'modules/_module_header'
@import 'modules/_module_footer'
@import 'modules/_module_breadcrumbs'
@import 'modules/_module_information'
@import 'modules/_module_top'
@import 'modules/_module_about'
@import 'modules/_module_feature'
@import 'modules/_module_base'
@import 'modules/_module_service'
@import 'modules/_module_faq'
@import 'modules/_module_errors'

.spNone
  +mediaSp()
    display none !important

.pcNone
  +mediaPc()
    display none !important

.pcBr
  +mediaSp()
    display none !important
.spBr
  +mediaPc()
    display none !important

.m_bold
  font-weight bold !important

.fullW
  width 100%

.halfW
  width 50%

// 汎用的に使う、ごく小規模な装飾のみ追記してください（css1行のみで成り立つような記述）
// 基本的にはmodules/以下で新しくファイルを作り、importする形で要素を追記すること
