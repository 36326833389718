// テキストリンクに装飾が付くデザインを記述
// ボタンデザインは_module_common_btnの方に記述すること
// 設置個所によりレイアウトが異なる場合は、ページ下部のテキストリンクレイアウトに調整記述を書くこと
// ==========================================================================
// テキストリンクデザイン
// ==========================================================================
.m_blankLink01
  text-decoration underline
  +mediaPc()
    &:hover
      text-decoration none
  &:after
    content ""
    display inline-block
    background url(image_path + "/common/icon_blank.png") center center no-repeat
    background-size 100%
    width 20px
    height 19px
    vertical-align top
    margin 2px 0 0 5px
    
.m_freeDial01
  padding 0 0 0 40px
  background url(image_path + "/common/icon_freedia.png") left center no-repeat
  background-size auto 22px
  +mediaSp()
    padding 0 0 0 30px
    background-size auto 16px
  
.m_pdfLink
  padding 0 0 0 35px
  background url(image_path + "/common/icon_pdf.png") left center no-repeat
  background-size auto 24px
  text-decoration underline
  font-size 1.8rem
  letter-spacing 0.01em
  +mediaPc()
    &:hover
      text-decoration none
  +mediaSp()
    padding 0 0 0 30px
    background-size auto 18px
    font-size 1.6rem