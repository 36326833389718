// カラーテーマ、再利用性のある小規模な装飾のみ記述すること
/* ==========================================================================*/
/* theme*/
/* ==========================================================================*/
.t_whiteBg
  background themeWhite
.t_colorBg01
  background-color themeMainColor01
.t_colorBg01--pc
  +mediaPc()
    background-color themeMainColor01
.t_colorBg02
  background-color themeSubColor01
.t_colorBg03
  background-color themeDarkGray01
.t_colorBg04
  background-color themeLightGray01
