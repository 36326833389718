.feature
  p
    font-size 1.8rem
    word-break normal
    word-wrap break-word
    +mediaSp()
      font-size 1.6rem
  .m_reasonTitle
    background themeLightGray01
    padding 40px 0
    margin-bottom 80px
    text-align center
    +mediaSp()
      padding 20px 0
      margin-bottom 40px
    h2
      font-size 3.2rem
      font-weight normal
      letter-spacing 0.1em
      line-height 1.4
      +mediaSp()
        font-size 1.8rem
        font-weight bold
      span
        color themeSubColor01
        display block
        position relative
        margin-bottom 50px
        +mediaSp()
          margin-bottom 30px
        &:after
          content ""
          display block
          background themeSubColor01
          width 100px
          height 2px
          position absolute
          bottom -10px
          right 0
          left 0
          margin auto
  .leadText
    text-align center
    line-height 2em
    font-size 1.8rem
    +mediaSp()
      text-align left
      font-size 1.4rem
  h3
    background themeSubColor01
    color themeWhite
    text-align center
    +mediaPc()
      height 80px
      line-height 80px
      font-weight normal
      font-size 2.4rem
    +mediaSp()
      font-size 1.6rem
      padding 10px 0
  .trainingLinkTitle
    font-weight bold
    font-size 2rem
    margin-bottom 10px
    +mediaSp()
      font-size 1.6rem
      text-align center
  .m_commonBtn03
    li
      +mediaPc()
        display inline-block
        margin-right 10px
        &:last-child
          margin-right 0
      +mediaSp()
        width 100%
        margin-bottom 15px
      a
        +mediaPc()
          height 60px
          line-height 20px
          border-radius 30px
          padding 9px 35px 0 30px
          span
            display block
            font-size 1.4rem
        +mediaSp()
          display block
          span
            font-size 1.2rem
            margin-left 10px
    &.history
      +mediaSp()
        margin-bottom 40px
      a
        padding 0 50px 0 30px
        &:before
          background url(image_path + '/common/icon_blank.png') left top no-repeat
          background-size 100%
          width 20px
          height 19px
        +mediaSp()
          padding 0 30px 0 18px
          font-size 1.4rem
          display block
          &:before
            width 10px
            height 10px
  .threePolicy
    dl
      +mediaPc()
        width 33%
        width calc(99.9% / 3)
        display inline-block
        vertical-align top
        padding 50px 28px 0
      +mediaSp()
        padding 20px 0 0
      dt
        font-size 2rem
        font-weight bold
        text-align center
        margin-bottom 40px
        +mediaPc()
          position relative
          &:after
            content ""
            display block
            background themeSubColor01
            width 2px
            height 50px
            position absolute
            top -50px
            right 0
            left 0
            margin auto
        +mediaSp()
          font-size 1.8rem
          margin-bottom 10px
          img
            width 70%
        span
          display inline-block
          width 60px
          height 60px
          line-height 60px
          text-align center
          border 2px solid themeSubColor01
          border-radius 30px
          color themeSubColor01
          margin-bottom 30px
          +mediaSp()
            width 40px
            height 40px
            line-height 40px
            margin-bottom 10px
      dd
        font-size 1.8rem
        word-break normal
        word-wrap break-word
        +mediaSp()
          font-size 1.6rem

