.m_serviceHeading
  text-align left
  +mediaSp()
    padding 20px 0 36px
  .titleBox01
    position relative
    .pageTitle
      padding-left 80px
      line-height 80px
      +mediaSp()
        padding-left 50px
        margin-right -15px
        font-weight bold
      &.support
        background url(image_path + '/common/icon_note.png') left center no-repeat
        background-size 60px
        +mediaSp()
          background-size 40px
      &.visitCare
        background url(image_path + '/common/icon_home.png') left center no-repeat
        background-size 60px
        +mediaSp()
          background-size 40px
      &.dayService
        background url(image_path + '/common/icon_truck.png') left center no-repeat
        background-size 60px
        +mediaSp()
          background-size 40px
  .serviceLink
    +mediaPc()
      position absolute
      right 0
      top -8px
    +mediaSp()
      margin-right -15px
    > span
      display inline-block
      margin-left 10px
      +mediaSp()
        margin 0 5px 0 0
        width 47%
      a
        display block
        height 60px
        line-height 20px
        padding 8px 40px 0 64px
        text-align center
        text-decoration none
        color themeMainColor01
        font-size 1.6rem
        font-weight bold
        position relative
        border solid 2px themeMainColor01
        border-radius 30px
        +mediaPc()
          &:hover
            transition .2s
        span
          font-size 1.4rem
        &:before
          content ""
          display block
          background url(image_path + '/common/arrow_left01.png') left top no-repeat
          background-size 100%
          width 8px
          height 14px
          position absolute
          top 0
          bottom 0
          right 14px
          margin auto
        +mediaSp()
          height 40px
          line-height 13px
          padding 6px 20px 0 16px
          font-size 1.3rem
          span
            font-size 1.0rem
          &:before
            right 10px
      &.support
        a
          background url(image_path + '/common/icon_note.png') 10px center no-repeat
          background-size 40px
          +mediaSp()
            background none
            background-size 30px
      &.visitCare
        a
          background url(image_path + '/common/icon_home.png') 10px center no-repeat
          background-size 40px
          +mediaSp()
            background none
            background-size 30px
      &.dayService
        a
          background url(image_path + '/common/icon_truck.png') 10px center no-repeat
          background-size 40px
          +mediaSp()
            background none
            background-size 30px
      &:hover
        +mediaPc()
          transform translateY(4px)
          transition .4s

.service
  p
    word-break normal
    word-wrap break-word
  +mediaSp()
    img
      max-width 100%
  .leadTextLarge
    text-align center
    color themeMainColor01
    font-size 2.4rem
    +mediaSp()
      text-align left
      font-size 1.8rem
  .leadText
    text-align center
    line-height 2em
    font-size 1.8rem
    +mediaSp()
      text-align left
      font-size 1.4rem

  // 提供サービスINDEX
  &.l_commonWrap02
    border-bottom 1px solid line02
    +mediaSp()
      .l_commonInner02
        padding 0
        margin-bottom 40px
  .inBox
    +mediaPc()
      padding 40px 40px 140px 380px
      position relative
      border-left 1px solid line02
      border-right 1px solid line02
      border-bottom 1px solid line02
    +mediaSp()
      padding 20px 15px 30px
      .img
        text-align center
    img
      +mediaPc()
        position absolute
        left 40px
        top 40px
        width 300px
      +mediaSp()
        margin 5px auto 15px
    .m_subTitle01
      text-align left
      margin-bottom 30px
      +mediaSp()
        font-size 2rem
        margin-top 10px
        margin-bottom 10px
      &--hasIcon
        padding 0 0 0 80px
        background-position left center
        +mediaSp()
          padding 10px 0 10px 80px
          min-height 60px
          margin-top 0
    .pointTitle
      color themeMainColor01
      font-weight bold
      margin 15px 0 10px
    .m_listStyle01
      li
        margin-bottom 0
    .btnArea
      +mediaPc()
        position absolute
        bottom 50px
        right 50px
        .m_commonBtn02
          display inline-block
          margin-left 40px
      +mediaSp()
        margin-top 20px
    &:nth-child(odd)
      background themeLightGray01
    &:last-child
      +mediaPc()
        border-bottom none

  // 居宅介護支援（ケアプラン）

  .supportServiceList
    background lighten(themeMainColor01, 85%)
    padding 20px
    box-sizing border-box
    margin-bottom 30px
    +mediaPc()
      width calc((100% - 30px) / 2)
      display inline-block
      &:nth-child(odd)
        margin-left 30px
    dt
      font-size 2.2rem
      line-height 60px
      margin-bottom 20px
      span
        background themeMainColor01
        border-radius 50%
        width 60px
        height 60px
        color themeWhite
        display inline-block
        text-align center
        margin-right 20px
    dd
      text-align justify
      word-break normal
      word-wrap break-word
      +mediaPc()
        min-height 80px
    +mediaSp()
      width 100%
      margin-bottom 15px
      dt
        font-size 1.8rem
        line-height 1.4em
        padding-left 40px
        position relative
        span
          width 30px
          height 30px
          line-height 30px
          position absolute
          left 0
          top -2px
  .supportServiceFlow
    li
      display table
      width 100%
      height 140px
      border 1px solid themeSubColor01
      margin-bottom 50px
      position relative
      +mediaSp()
        display block
        height auto
      &:not(.step4):after
        content ""
        display block
        width 0
        height 0
        border-style solid
        border-width 40px 60px 0 60px
        border-color lighten(themeSubColor01, 50%) transparent transparent transparent
        position absolute
        bottom -41px
        left 50%
        margin-left -60px
      &.step1
        background url(image_path + '/service/support/img_flow01.png') right center no-repeat
      &.step2
        background url(image_path + '/service/support/img_flow02.png') right center no-repeat
      &.step3
        background url(image_path + '/service/support/img_flow03.png') right center no-repeat
      &.step4
        background url(image_path + '/service/support/img_flow04.png') right center no-repeat
      +mediaSp()
        &.step1
          background url(image_path + '/service/support/img_flow01_sp.png') center bottom no-repeat
          background-size 310px
        &.step2
          background url(image_path + '/service/support/img_flow02_sp.png') center bottom no-repeat
          background-size 310px
        &.step3
          background url(image_path + '/service/support/img_flow03_sp.png') center bottom no-repeat
          background-size 310px
        &.step4
          background url(image_path + '/service/support/img_flow04_sp.png') center bottom no-repeat
          background-size 310px
      span
        display table-cell
        vertical-align middle
        &.step
          background themeSubColor01
          color themeWhite
          text-align center
          width 120px
          font-size 2rem
          letter-spacing 0.1em
          +mediaSp()
            width 25%
            height 80px
            line-height 80px
            font-size 1.4rem
            display inline-block
        &.title
          background lighten(themeSubColor01, 85%)
          color themeSubColor01
          text-align center
          width 260px
          font-size 1.8rem
          font-weight bold
          +mediaSp()
            font-size 1.8rem
            width 75%
            height 80px
            line-height 80px
            display inline-block
        &.description
          padding 0 320px 0 30px
          word-break normal
          word-wrap break-word
          +mediaSp()
            padding 15px 15px 140px
            display block
      +mediaSp()
        &.step3
        &.step4
          span.title
            line-height 25px
            padding 16px 0 14px

  .m_commonBtn02.btnArea
    background lighten(themeMainColor01, 85%)
    padding 40px 15px
    +mediaSp()
      padding 20px 15px
    a
      +mediaPc()
        width 420px

  h3.trainingTableHead
    font-size 3.2rem
    font-weight normal
    background themeSubColor01
    color themeWhite
    margin-bottom 20px
    text-align center
    +mediaPc()
      height 80px
      line-height 80px
    +mediaSp()
      font-size 1.6rem
      line-height 1.4em
      padding 5px 0
      margin-bottom 10px

  .trainingTable
    display flex
    +mediaSp()
      flex-wrap wrap
    dl
      padding 30px
      +mediaSp()
        padding 15px
      dt
        font-weight bold
        font-size 2.4rem
        margin-bottom 20px
        position relative
        padding-left 80px
        line-height 60px
        span.number
          display inline-block
          width 60px
          height 60px
          color themeWhite
          border-radius 30px
          text-align center
          position absolute
          left 0
        span.period
          font-size 1.8rem
          margin-left 30px
        +mediaSp()
          line-height 30px
          padding-left 40px
          font-size 1.6rem
          margin-bottom 10px
          span.number
            width 30px
            height 30px
            top 2px
          span.period
            margin-left 0
            display block
            font-size 1.4rem
            line-height 1.2
      dd
        word-break normal
        word-wrap break-word
        span
          display block
          font-weight bold
          +mediaPc()
            margin-bottom 10px

    .trainingTable01
      flex-basis 8%
      background themeLightGray01
      display flex
      flex-direction column
      justify-content space-between
      text-align center
      padding 10px
      +mediaSp()
        flex-basis 20%
        padding 0
        background url(image_path + '/service/support/bg_arrow_period_01_sp.png') center top repeat-y themeLightGray01
        background-size 10px
      span
        background themeSubColor01
        color themeWhite
        font-size 2rem
        font-weight bold
        line-height 40px
        position relative
        display block
        +mediaPc()
          &:not(.last)
            &:after
              position absolute
              content " "
              background url(image_path + '/service/support/bg_arrow_period.png') center bottom no-repeat
              height 305px
              width 100%
              left 0
              top 40px
        +mediaSp()
          font-size 1.4rem
          &:not(:first-child)
            &:after
              position absolute
              content " "
              background url(image_path + '/service/support/bg_arrow_period_02_sp.png') center bottom no-repeat
              background-size 40px
              width 100%
              left 0
              top -20px
              height 20px

    .trainingTable02
      display flex
      flex-direction column
      flex-basis 60%
      margin 0 20px
      +mediaSp()
        flex-basis 77%
        margin 0 0 0 3%
      .trainingBox01
        background lighten(#29ac35, 85%)
        flex-grow 1
        span.number
          background #29ac35
        span.period
          color #29ac35
      .trainingBox02
        background lighten(#17a081, 85%)
        flex-grow 1
        margin 20px 0
        +mediaSp()
          margin 10px 0
        span.number
          background #17a081
        span.period
          color #17a081
      .trainingBox03
        background lighten(#1996ba, 85%)
        flex-grow 1
        span.number
          background #1996ba
        span.period
          color #1996ba

    .trainingTable03
      flex-basis 32%
      +mediaPc()
        min-width 380px
      +mediaSp()
        margin-top 10px
        flex-basis 100%
      .trainingBox04
        background lighten(#9767c0, 85%)
        +mediaPc()
          height 100%
          padding-top 80px
        dt
          line-height 30px
          span.number
            background #9767c0
            line-height 60px
            +mediaSp()
              line-height 30px
          span.period
            color #9767c0
            margin-left 0
        dd
          img
            margin-top 40px
            +mediaSp()
              margin-top 20px
              max-width 100%

  // 訪問介護（ホームヘルプ）

  .trainingDescription
    margin-bottom 20px
    dt
      font-weight bold
      margin-bottom 10px

  .m_aboutBenesseText
    margin-bottom -20px
    dt
      color themeMainColor01
      font-size 2rem
      line-height 1.4
      margin-bottom 5px
      +mediaSp()
        font-size 1.8rem
    dd
      margin-bottom 20px
      font-size 1.4rem

  .visitcareServiceList
    +mediaPc()
      display table
      width 100%
    dl
      +mediaPc()
        display table-cell
        width 50%
        vertical-align top
        &:not(:first-child)
          padding-left 30px
      +mediaSp()
        margin-bottom 20px
      dt
        color themeMainColor01
        font-weight bold
        font-size 2.4rem
        line-height 60px
        margin-bottom 20px
        +mediaSp()
          line-height 30px
          font-size 1.8rem
        span
          display inline-block
          border 2px solid themeMainColor01
          height 60px
          width 60px
          border-radius 30px
          text-align center
          margin-right 20px
          +mediaSp()
            line-height 26px
            height 30px
            width 30px
            margin-right 10px
      dd
        word-break normal
        word-wrap break-word
        text-align justify
        +mediaPc()
          position relative
          padding-left 260px
        .img
          +mediaPc()
            position absolute
            top 0
            left 0
          +mediaSp()
            margin 10px 0
            text-align center
            img
              width 60%
        span
          display block
          font-size 1.4rem
          color themeDarkGray01
          +mediaPc()
            margin-top 20px

  .serviceCaseBox
    background themeLightGray01
    padding 40px
    position relative
    +mediaSp()
      padding 15px
    h3
      font-weight bold
      font-size 2.4rem
      letter-spacing 0.1
      color themeSubColor01
      margin-bottom 30px
      +mediaSp()
        font-size 1.6rem
        line-height 1.4
        margin-bottom 15px
    .profile
      +mediaPc()
        width 340px
        height 90px
        position absolute
        right 0
        top 40px
      font-size 1.6rem
      padding 14px 0 0 100px
      background url(image_path + '/service/visit_care/img_profile_c.png') 10px center no-repeat themeWhite
      +mediaSp()
        background-size 40px
        font-size 1.2rem
        padding 10px 0 10px 60px
        margin-bottom 10px
        line-height 1.4
      span
        font-weight bold
        font-size 2rem
        +mediaSp()
          font-size 1.4rem

  // 通所介護（デイサービス）
  +mediaPc()
    .recommendText
      margin-left 20px

  .tabDayService
    display table
    width 100%
    li
      display table-cell
      text-align center
      vertical-align middle
      height 80px
      width 50%
      font-weight bold
      font-size 2.4rem
      letter-spacing 0.1
      position relative
      +mediaPc()
        cursor pointer
      +mediaSp()
        font-size 1.4rem
      &.tabShort
        background lighten(#29ac35, 80%)
        color #29ac35
        &.current
          background #29ac35
          color themeWhite
          &:after
            bottom -80px
            left 50%
            border solid transparent
            content " "
            height 0
            width 0
            position absolute
            pointer-events none
            border-top-color #29ac35
            border-width 40px
            margin-left -40px
            +mediaSp()
              bottom auto
              top 100%
              border-width 20px
              margin-left -20px
      &.tabLong
        background #d1ece6
        color #17a081
        &.current
          background #17a081
          color themeWhite
          &:after
            display block
            bottom -80px
            left 50%
            border solid transparent
            content " "
            height 0
            width 0
            position absolute
            pointer-events none
            border-top-color #17a081
            border-width 40px
            margin-left -40px
            +mediaSp()
              bottom auto
              top 100%
              border-width 20px
              margin-left -20px
    &--bottom
      li.tabShort.current
        &:after
          top -80px
          bottom auto
          left 50%
          border solid transparent
          content " "
          height 0
          width 0
          position absolute
          pointer-events none
          border-color rgba(136, 183, 213, 0)
          border-bottom-color #29ac35
          border-width: 40px
          margin-left -40px
          +mediaSp()
            top auto
            bottom 100%
            border-width 20px
            margin-left -20px
      li.tabLong.current
        &:after
          top -80px
          bottom auto
          left 50%
          border solid transparent
          content " "
          height 0
          width 0
          position absolute
          pointer-events none
          border-color rgba(136, 183, 213, 0)
          border-bottom-color #17a081
          border-width 40px
          margin-left -40px
          +mediaSp()
            top auto
            bottom 100%
            border-width 20px
            margin-left -20px

  .tabPanel
    display none
    &.current
      display block
    .m_contentTitle01
      background none
      padding-bottom 0
    .imgTimeTable
      +mediaSp()
        text-align center
        img
          height 90vh
          margin 0 auto

    // 短時間デイサービス
    &.tabShort
      h2:after
        background #29ac35
      .serviceCaseBox
        h3
          color #29ac35
          +mediaPc()
            line-height 90px
        .profile
          background url(image_path + '/service/day_service/img_profile_a.png') 10px center no-repeat themeWhite
          +mediaSp()
            background-size 40px
            width 100%
      +mediaSp()
        .m_imgRightBox
          .img
            margin-top 10px
      .ptDescription
        overflow hidden
        h4
          color themeMainColor01
          font-weight bold
          margin 40px 0 20px
        img
          +mediaPc()
            float right
            margin-top -100px
        .captionText
          font-size 1.4rem
          color themeDarkGray01
          +mediaSp()
            font-size 1.2rem

    // 滞在型デイサービス
    &.tabLong
      h2:after
        background #17a081
      .serviceCaseBox
        h3
          color #17a081
        .profile
          background url(image_path + '/service/day_service/img_profile_b.png') 10px center no-repeat themeWhite
          +mediaSp()
            background-size 40px
      .longDayServiceList
        li
          display table
          width 100%
          height 180px
          border 1px solid #17a081
          margin-bottom 40px
          position relative
          +mediaSp()
            display block
            height auto
            margin-bottom 20px
          span
            display table-cell
            vertical-align middle
            &.title
              background #d1ece6
              color #17a081
              text-align center
              width 200px
              font-size 2.2rem
              font-weight bold
              +mediaSp()
                font-size 1.8rem
                width 100%
                height 40px
                line-height 40px
                display inline-block
            &.description
              padding 0 340px 0 30px
              +mediaSp()
                padding 15px 15px 50vw
                display block
          &.step1
            background url(image_path + '/service/day_service/img_long_02.jpg') right center no-repeat
            +mediaSp()
              background url(image_path + '/service/day_service/img_long_02_sp.jpg') center bottom no-repeat
              background-size contain
          &.step2
            background url(image_path + '/service/day_service/img_long_03.jpg') right center no-repeat
            +mediaSp()
              background url(image_path + '/service/day_service/img_long_03_sp.jpg') center bottom no-repeat
              background-size contain
          &.step3
            background url(image_path + '/service/day_service/img_long_04.jpg') right center no-repeat
            +mediaSp()
              background url(image_path + '/service/day_service/img_long_04_sp.jpg') center bottom no-repeat
              background-size contain
              span.title
                line-height 25px
                padding 8px 0 6px
                height auto
          &.step4
            background url(image_path + '/service/day_service/img_long_05.jpg') right center no-repeat
            span.title
              span
                display block
                font-size 1.8rem
            +mediaSp()
              background url(image_path + '/service/day_service/img_long_05_sp.jpg') center bottom no-repeat
              background-size contain
              span.title
                line-height 25px
                padding 8px 0 6px
                height auto
                span
                  font-size 1.4rem

