// ==========================================================================
// フッターデザイン
// ==========================================================================
.m_footer
  +mediaSp()
    position relative
    z-index 1
  .grayBg
    background themeDarkGray01
    padding 60px 0
    +mediaSp()
      padding 38px 0 20px
    +mediaPc()
      position relative
      border-top 1px solid line01
    .pagetopBtn
      display none
      position fixed
      z-index 5000
      right 8vw
      bottom 100px
      width 80px
      height 80px
      border-radius 60px
      background themeMainColor01
      &.fixed
        position absolute
        top -60px
        margin-right 0
        transform translateY(-50%)
      +mediaSp()
        width 30px
        height 30px
        right 20px
        bottom 50px
        border 1px solid line01
        background themeWhite
        &.fixed
          top 0
      &:hover
        opacity .8
      svg
        position absolute
        z-index 2
        left 50%
        top 50%
        transform translate(-50%, -50%)
        margin-top -2px
        fill themeWhite
        +mediaSp()
          width 13px
          margin-top 0px
          fill themeMainColor01

  .privacyText
    margin-bottom 25px
    p.toggleBox
      color themeMainColor01
      font-size 1.2rem
      &::before
        content: "[＋]"
        display inline-block
        margin-right 5px
      &.open
        &::before
          content: "[－]"
    p.innerText
      display none
      color themeWhite
      font-size 1.2rem
      word-break normal
      word-wrap break-word
      a
        pointer-events: none;
      +mediaSp()
        padding 0 15px
        a
          pointer-events auto
          color themeWhite
  .l_commonInner02
    +mediaSp()
      padding 0
  .footerCopy
    display block
    font-size 2.4rem
    color text02
    line-height 2
    text-align center
    position relative
    z-index 1
    padding-top 30px
    &.l_dropShadow01
      padding-top 50px
    +mediaSp()
      display none
    .inner
      width 100%
      margin-left auto
      margin-right auto
      +mediaPc()
        max-width 1040px
        padding-left 20px
        padding-right 20px
    .copyText
      padding 100px 208px
      display block
      background url(image_path + "/common/img_footer_logo01.png") left center no-repeat, url(image_path + "/common/img_footer01.png") right center no-repeat
  .l_commonInner01
    padding 75px 0 50px
    +mediaPc()
      position relative
      z-index 1
    +mediaSp()
      padding 0

  .footerInner01
    .spInquiry
      display none
      +mediaSp()
        display block
      .m_commonBtn01
        margin 0 0 15px
        padding 0 15px
        a
          font-size 2rem
          text-align center
          padding 8px 15px
          box-shadow 0 3px 5px #4a4e4c
          +mediaSp()
            &::before
              width 26px
              height 20px
              vertical-align -3px
              background-image url(image_path + "/common/icon_mail_sp.png")
              background-size 26px 20px
      .telText
      .privacyText
        p.toggleBox
          text-align center
          color themeWhite
    .spNav
      display none
      width 100%
      background themeDarkGray01
      color themeWhite
      border-top 1px solid line01
      +mediaSp()
        display block
      .spEntry
        padding 30px 10px
        background #5d5d5f
        a
          display block
      .spNav01
        li
          display block
          transition .4s
          .circle
            display: none
          a,.toggleBtn
            cursor pointer
            display block
            padding 15px 10px
            color themeWhite
            font-size 1.6rem
            line-height 1.4
            text-decoration none
            border-bottom 1px solid line01
            position relative
            z-index 1
          a:not(.toggleBtn)
            &:after
              width 10px
              height 19px
              display block
              content ""
              position absolute
              right 10px
              top 50%
              transform translateY(-50%)
              background url(image_path + "/common/arrow_left02.png") center center no-repeat
              background-size 10px auto
          .toggleBtn
            &:before, &:after
              width 21px
              height 2px
              display block
              content ""
              background themeMainColor01
              position absolute
              z-index 2
              right 10px
              top 50%
              transform translateY(-50%) rotate(0deg)
            &:before
              transform translateY(-50%) rotate(90deg)
              transition .4s
            &.on
              &:before
                transform translateY(-50%) rotate(0deg)
          &.on
            background rgba(0,0,0,0.3)
          .intNav01
            display none
            li
              a
                font-size 1.6rem
                padding 15px 20px
                font-weight normal
                position relative
                z-index 1

    .contents01
      +mediaPc()
        display flex
        flex-wrap nowrap
        justify-content space-between
        align-items stretch
        padding-bottom 30px
        border-bottom 1px solid line03
      +mediaSp()
        display none
      .columnBox01
        +mediaPc()
          flex-grow 1
        &:not(:first-of-type)
          border-left 1px solid line03
          padding-left 50px
        .title
          font-size 2.4rem
          font-weight bold
          color themeText05
          line-height 1
          +mediaPc()
            margin-bottom 15px
          +mediaSp()
            line-height 1.4
            font-size 1.6rem
            font-weight normal
            padding 16px 30px 16px 10px
            box-sizing border-box
            border-bottom 1px solid line04
            position relative
            z-index 1
            &:after,&:before
              content ""
              display block
              width 20px
              height 2px
              border-radius 3px
              background themeWhite
              position absolute
              z-index 2
              right 10px
              top 50%
              transform translateY(-50%)
            &:after
              transform translateY(-50%) rotate(90deg)
              transition .4s
            &.on
              &:after
                transform translateY(-50%) rotate(0deg)
        .contents
          +mediaSp()
            display none
        .list
          display block
          line-height 1
          li
            display block
            position relative
            font-size 1.8rem
            font-weight bold
            &::before
              content ""
              width 5px
              height 10px
              display inline-block
              background url(image_path + '/common/arrow_left02.png') left center no-repeat
              background-size 5px auto
              position absolute
              left 0
              top 7px
              a
                vertical-align top
            &.nest
              margin-bottom 0
              a
                display inline-block


              .innerList
                vertical-align top
                display inline-block
                li
                  &::before
                    display none
                  &::after
                    display none
                  .crossIcon, .indent
                    padding-left 25px
                    position relative
                    a
                      font-size 1.6rem
                      font-weight normal
                      position relative
                      display inline-block
                      padding-left 30px
                      &::before, &::after
                        content: ""
                        background themeMainColor01
                        display block
                        position absolute
                        left 0
                      &::before
                        width 1px
                        height 15px
                        top 7px
                      &::after
                        width 10px
                        height 1px
                        top 11px
                      +mediaSp()
                        &::before
                          display none
                  &:last-child
                    margin-bottom 0
                    a
                      margin-bottom 0
                  .crossIcon
                    a
                      margin-bottom 10px

            +mediaPc()
              margin-bottom 30px
            +mediaSp()
              background bgWhite
              line-height 1
              &:not(:last-of-type)
                border-bottom 1px solid line01
          a
            display inline-block
            vertical-align middle
            font-size 1.8rem
            color themeWhite
            line-height 1.4
            +mediaPc()
              padding-left 12px
              text-indent -12px
            +mediaSp()
              text-decoration none
              padding 15px 30px 15px 15px
              font-size 1.6rem
              display block
              position relative
              z-index 1
              &:after
                display block
                content ""
                width 10px
                height 20px
                position absolute
                z-index 2
                right 10px
                top 50%
                transform translateY(-50%)
                background url(image_path + "/arrow/arrow_right_theme_color01.svg") center center no-repeat
                background-size contain
            &:before
              content ""
              width 5px
              height 5px
              border-radius 5px
              background themeText05
              display inline-block
              vertical-align middle
              margin-right 7px
              margin-top -3px
              +mediaSp()
                display none
  .footerInner02
    padding 35px 30px 0
    +mediaSp()
      padding 0
      background themeDarkGray02
    .privacyText
      +mediaSp()
        display none
    .contents01
      .title01
        font-size 2.4rem
        font-weight bold
        color themeWhite
        line-height 1.4
        margin-bottom 20px
        +mediaSp()
          margin-bottom 0
          font-size 1.8rem
          font-weight bold
          padding 16px 10px
          border-bottom 1px solid line01
      .listBox01
        +mediaPc()
          display table
          table-layout fixed
          &:not(:first-of-type)
            margin-top 5px
        +mediaSp()
          display block
        .title02
          display table-cell
          vertical-align top
          width 160px
          color textOrange
          font-weight bold
          font-size 1.8rem
          line-height 1.4
          padding-top 3px
          +mediaSp()
            display block
            width 100%
            padding 16px 30px 16px 10px
            box-sizing border-box
            font-weight normal
            font-size 1.6rem
            border-bottom 1px solid text04
            position relative
            z-index 1
            color line01
            &:after,&:before
              content ""
              display block
              width 20px
              height 2px
              border-radius 3px
              background themeLightGray01
              position absolute
              z-index 2
              right 10px
              top 50%
              transform translateY(-50%)
            &:after
              transform translateY(-50%) rotate(90deg)
              transition .4s
            &.on
              &:after
                transform translateY(-50%) rotate(0deg)
        .contents
          display table-cell
          vertical-align top
          +mediaSp()
            display none
          .list
            display block
            li
              display inline-block
              vertical-align baseline
              +mediaPc()
                &:not(:last-of-type)
                  &:after
                    content "|"
                    margin 0 5px
                    vertical-align baseline
                    display inline-block
                    color themeWhite
              +mediaSp()
                display block
                background bgWhite
                border-bottom 1px solid line01
                font-size 1.6rem
              a
                display inline-block
                color text04
                +mediaSp()
                  display block
                  padding 16px 30px 16px 20px
                  text-decoration none
                  font-size 1.4rem
                  position relative
                  z-index 1
                  word-break normal
                  word-wrap break-word
                  &:after
                    display block
                    content ""
                    width 10px
                    height 20px
                    position absolute
                    z-index 2
                    right 10px
                    top 50%
                    transform translateY(-50%)
                    background url(image_path + "/common/arrow_left_gray02.png") center center no-repeat
                    background-size contain
  .footerInner03
    padding 0 30px
    +mediaSp()
      width 100%
      padding 20px 10px
      box-sizing border-box
      font-size 1.4rem
    .snsList
      +mediaSp()
        text-align center
      li
        display inline-block
        &:not(:first-of-type)
          margin-left 5px
        a
          display block
          &:hover
            opacity .8
        img
          display block
          height 20px
          +mediaSp()
            height 28px
            margin-bottom 5px
    .text01
      padding-top 20px
      font-size 1.2rem
      color text03
      word-break break-word
      +mediaSp()
        padding-top 0
        font-size 1.4rem
        color themeWhite
