// ==========================================================================
// バナー系パーツ
// ==========================================================================
.m_recruitBanner01
  padding 80px 0 140px
  +mediaSp()
    padding 40px 15px 70px
  .bnr
    display table
    background themeLightGray01
    width 1000px
    margin auto
    border solid 1px themeMainColor01
    +mediaSp()
      display block
      width 100%
      position relative
      border-width 2px
    .img
    .txtArea
      display table-cell
      vertical-align middle
      +mediaSp()
        display block
    .img
      +mediaSp()
        img
          width 100%
    .txtArea
      padding 0 60px
      +mediaSp()
        padding 0 22px
        position absolute
        bottom 23px
        left 0
        right 0
      .lead
        margin 0 0 40px
        +mediaSp()
          display none

