/*** base ***/
.m__baseHeading
  +mediaSp()
    padding 30px 0 25px



/*** baseDetail ***/
.m_baseDetailHeading
  text-align left
  padding-bottom 24px
  +mediaSp()
    padding 20px 0 10px
    h1
      font-weight normal
  .category
    font-size 0
    +mediaSp()
      margin 0
    span
      display inline-block
      margin 0 10px 15px 0
      text-align center
      font-size 1.8rem
      font-weight bold
      a
        display inline-block
        height 40px
        padding 0 15px 0 20px
        line-height 36px
        text-decoration none
        transition .2s
        border-width 2px
        border-style solid
        border-radius 20px
        +mediaPc()
          &:hover
            transform translateY(4px)
            transition .4s
      +mediaSp()
        margin 0 6px 10px 0
        line-height 1
        a
          height 20px
          padding 0 10px
          line-height 20px
          font-size 0.9rem
          border-radius 10px
          border-width 1px
      &.support
        a
          border-color tagSupport
          color tagSupport
          &:before
            background tagSupport
      &.visitCare
        a
          border-color tagVisitCare
          color tagVisitCare
          &:before
            background tagVisitCare
      &.longDayService
        a
          border-color tagLongDayService
          color tagLongDayService
          &:before
            background tagLongDayService
      &.shortDayService
        a
          border-color tagShortDayService
          color tagShortDayService
          &:before
            background tagShortDayService
      &.communityCenter
        a
          border-color tagCommunityCenter
          color tagCommunityCenter
          &:before
            background tagCommunityCenter


.m_baseDetailContent
  clearfix()
  padding 50px 0 83px
  position relative
  +mediaSp()
    padding 20px 0 260px
  .columnRight
    width 400px
    float right
    +mediaSp()
      width 100%
      float none
    .img
      margin 0 0 40px
      img
        max-width: 400px
      +mediaSp()
        margin 0 72px 20px
        text-align center
        img
          width 100%
    .googleMap
      width 100%
      height 400px
      +mediaSp()
        position absolute
        height 200px
        bottom 40px
      iframe
        width 100%
        height 100%
  .columnLeft
    width 640px
    float left
    +mediaSp()
      width 100%
      float none
    .detailTable
      width 100%
      border-bottom solid 1px #ccc
      +mediaSp()
        display block
        border solid 1px #ccc
      > tbody
        +mediaSp()
          display block
        > tr
          border-top solid 1px #ccc
          +mediaSp()
            display block
            border none
          > th
            background themeLightGray01
            width 160px
            padding 18px 0
            font-size 1.4rem
            vertical-align middle
            +mediaSp()
              display block
              width 100%
              padding 5px 0
              font-size 1.2rem
          > td
            padding 18px 27px
            +mediaSp()
              display block
              padding 15px 10px
            .inTable
              tr + tr
                th
                td
                  padding 12px 0 0
              th
                width 200px
                text-align left
                color themeDarkGray01
                font-size 1.4rem
                vertical-align middle
                +mediaSp()
                  width 150px
                  font-size 1.2rem
            .midashi
                display block
                margin 15px 0 5px
                font-size 1.4rem
                font-weight bold
                color #f36c2a
                +mediaSp()
                  margin 15px 0 0
                  font-size 1.2rem
                &:first-child
                  margin-top 0

.m_baseDetailSlider
  background #fef0e9
  padding 40px 0
  margin-bottom 80px
  +mediaSp()
    padding 20px 0
    margin-bottom 40px
  .l_commonInner01
    > .js_baseSlider
      width 800px
      margin 0 auto
      +mediaSp()
        width 290px
      li + li
        display none
  .js_baseSlider
    li
      position relative
      text-align center
      overflow hidden
      .cap
        background rgba(0, 0, 0, 0.5)
        padding 15px 20px
        font-size 1.8rem
        color themeWhite
        position absolute
        bottom 0
        left 0
        right 0
        margin auto
        text-align left
        +mediaSp()
          font-size 1.4rem
          padding 10px 15px
          width 260px
      img
        width auto
        height 600px
        +mediaSp()
          height 195px
  .bx-wrapper
    width 800px
    margin 0 auto
    position relative
    +mediaSp()
      width 290px
      padding 0 15px
      box-sizing border-box
    .bx-controls
      .bx-pager
        padding 24px 0 6px
        text-align center
        font-size 0
        +mediaSp()
          padding 20px 0 0
        .bx-pager-item
          display inline-block
          margin 0 7px
          a
            display block
            background #dcdcdc
            width 10px
            height 10px
            text-indent -9999px
            border-radius 50%
            &.active
              background themeMainColor01
              box-shadow none
      .bx-controls-direction
        a
          display block
          width 21px
          height 37px
          text-indent -9999px
          position absolute
          top 0
          bottom 40px
          margin auto
          z-index 100
          +mediaSp()
            width 15px
            height 100%
            bottom 30px
          &.bx-next
            background url(image_path + "/common/arrow_left01.png") center center no-repeat
            background-size 100%
            right -50px
            +mediaSp()
              background-image url(image_path + "/common/arrow_left02.png")
              right -3vw
          &.bx-prev
            background url(image_path + "/common/arrow_right01.png") center center no-repeat
            background-size 100%
            left -50px
            +mediaSp()
              background-image url(image_path + "/common/arrow_right02.png")
              left -3vw

.m_baseDetailGreeting
  padding 0 0 80px
  +mediaSp()
    padding 0 0 46px
  .greetingBox
    padding 20px 0
    border solid 5px #dfefc3
    +mediaPc()
      display table
      width 100%
      border-spacing 40px
    +mediaSp()
      padding 28px 20px
      border-width 2px
      display flex
      flex-direction column
    .img
      position relative
      +mediaPc()
        display table-cell
        width 200px
      +mediaSp()
        order 2
        display table
        width 100%
        float none
      .photo
        img
          width 100%
        +mediaSp()
          display table-cell
          width 100px
      .person
        background rgba(149,202,57,0.8)
        padding 10px 0
        text-align center
        color themeWhite
        font-size 2rem
        position absolute
        bottom 0
        left 0
        right 0
        margin auto
        +mediaSp()
          background themeSubColor01
          display table-cell
          padding 0 0 0 15px
          position static
          text-align left
          font-size 1.4rem
          vertical-align middle
       span
         display block
         font-size 1.2rem
    .txtArea
      +mediaPc()
        display table-cell
        vertical-align top
      +mediaSp()
        order 1
        width 100%
        margin 0 0 23px
        padding 0
        float none
      .m_blockTitle01
        margin 0 0 30px
        font-weight normal
        +mediaSp()
          margin 0 0 15px
          font-size 1.6rem
          font-weight bold

.m_baseInquiryBox01
  .inquiryInfo
    .m_telephoneBox01
      +mediaSp()
        display inline-block
      a.telNumber
        line-height 0.8
        text-decoration none
        font-size 5.4rem
        font-family 'Roboto', sans-serif
        font-weight normal
        +mediaSp()
          background-size 31px 32px
          padding-left 40px
          font-size 3.6rem
          span
            letter-spacing -1.3px

      p
        +mediaSp()
          text-align left
          padding-left 43px
          font-size 1.2rem
  +mediaSp()
    p
      margin 0 0 30px
      color themeDarkGray01
      font-size 1.2rem
      letter-spacing 0.06rem
    p.inquiryTitle
      font-weight bold
    .inquiryInfo
      .m_commonBtn01
        display block
        a
          display block
