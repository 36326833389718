// ==========================================================================
// ヘッダーデザイン
// ==========================================================================
.m_header
  position relative
  z-index 100
  &:after
    position absolute
    z-index 2
    content ""
    bottom: 0
    left: 0
    width 100%
    height 13px
    display block
    transform translateY(100%)
    gradient(rgba(0,0,0,0.1), 10%, rgba(0,0,0,0), 100%)
    +mediaSp()
      height 7px
  .headerInner02
    background bgWhite
    +mediaSp()
      position relative
      z-index 1
    .l_commonInner02
      +mediaSp()
        padding 0 10px
    .headerNav02
      display flex
      justify-content space-between
      align-items center
      padding 20px 0 15px
      +mediaSp()
        padding 10px 0
      .btnSpMenu
        display none
        +mediaSp()
          display block
          border-radius 4px
          width 30px
          height 30px
          background themeMainColor01
          position relative
          z-index 1
          cursor pointer
          box-shadow 0 2px 3px rgba(shadowColor01, 0.8)
          span
            font-size 0
            text-indent -9999px
            display block
            width 18px
            height 3px
            border-radius 1px
            background rgba(255,255,255,1)
            position absolute
            z-index 2
            top 50%
            left 50%
            transform translate(-50%, -50%)
            transition .4s
            &:before,&:after
              content ""
              width 18px
              height 3px
              display block
              position absolute
              z-index 2
              background themeWhite
            &:before
              top -6px
              transition .2s
            &:after
              top 6px
              transition .2s
          &.on
            span
              background rgba(255,255,255,0)
              &:before
                top 0
                transform rotate(135deg)
              &:after
                top 0
                transform rotate(-135deg)
      .logoArea
        +mediaPc()
          margin 5px 0 0
        .logo01
          display inline-block
          vertical-align middle
          margin 0 40px 0 10px
          +mediaSp()
            width 33px
            margin-right 20px
            margin-left 0
          a
            display block
            &:hover
              opacity .8
            img
              display block
              width 100%
        .logo02
          display inline-block
          vertical-align middle
          color themeDarkGray01
          .text01
            display block
            line-height 1
            margin 0 0 8px
            font-size 1.2rem
            font-weight normal
            span
              &.sp
                display none
            +mediaSp()
              font-size .8rem
              color themeDarkGray01
              margin 0 0 3px
              letter-spacing normal
              span
                &.pc
                  display none
                &.sp
                  display block
          .inner
            display block
            line-height 1
            .img
              display inline-block
              vertical-align middle
              +mediaSp()
                width 110px
              a
                display block
                &:hover
                  opacity .8
                img
                  display block
                  width 100%
            .text02
              margin-left 18px
              display inline-block
              vertical-align middle
              font-size 2.1rem
              line-height 1
              +mediaSp()
                margin-left 10px
              a
                display block
                color text02
                text-decoration none
                line-height 1
                span
                  display inline-block
                  vertical-align text-bottom
                  color themeMainColor01
                +mediaSp()
                  font-size 1.8rem
                &:hover
                  opacity .8
      .contactArea
        +mediaSp()
          display none
        ul.textLink
          display inline-block
          li
            margin-right 30px
            a
              text-decoration none
              padding-left 12px
              background url(image_path +'/common/arrow_left02.png') left center no-repeat
              background-size 5px 9px
              color themeDarkGray01
              font-size 1.5rem
              &:hover
                text-decoration underline
        .telInfo
          display inline-block
          vertical-align bottom
          box-sizing border-box
          line-height 1
          border 1px solid themeMainColor01
          border-radius 6px
          padding 10px 20px 5px 60px
          position relative
          &::before
            content: ""
            display block
            position absolute
            left 0
            top 0
            width 60px
            height 100%
            background url(image_path + '/common/icon_header_tel.png') center center no-repeat
            background-size 36px
          .tel
            margin 0 0 5px 0
          .time
            font-size 1.2rem
            color text02
    .headerNav
      ul.gnav
        display flex
        justify-content: space-between;
        align-items center
        li
          flex 1
          text-align center
          position relative
          padding-bottom 10px
          .m_commonBtn01
            border-left 1px solid line02
          a
            display block
            border-left 1px solid line02
            font-weight bold
            font-size 1.8rem
            text-decoration none
            padding 5px 10px
            position relative
            &::after
              content ""
              display block
              width 0
              height 5px
              background themeMainColor01
              position absolute
              left 50%
              bottom -10px
              transform translateX(-50%)
              /*transition .4s*/
            &:hover
              &::after
                width 100%
                transition .4s
          &.current
            a
              &::after
                width 100%
          .intNav01
            display none
            width 181px
            box-shadow 0px 5px 8px rgba(0, 0, 0, 0.1)
            position absolute
            z-index 100
            left -1px
            bottom 0
            transform translateY(100%)
            li
              background rgba(themeDarkGray01, 0.9)
              display block
              padding-bottom 0
              &:hover
                background rgba(themeDarkGray01, 0.7)
              &:not(:first-of-type)
                border-top 1px solid themeWhite
              a
                border-left 0
                display block
                padding 13px 8px 13px 0px
                text-decoration none
                /*transition .2s*/
                color themeWhite
                font-size 1.4rem
                span
                  font-size 1.1rem
                  font-weight normal
                  vertical-align baseline
                  padding-left 2px
                &:after
                  content ""
                  width 5px
                  height 11px
                  position absolute
                  right 10px
                  left auto
                  top 50%
                  transform translateY(-50%)
                  background url(image_path + "/common/arrow_left02.png") center center no-repeat
                  background-size contain
                &:hover
                  background themeText02
                  color textWhite
                  span:before
                    background url(image_path + "/arrow/arrow_right_white01.svg") center center no-repeat
                    background-size contain
          .m_commonBtn01
            display block
            padding 0 0 0 10px
            text-align center
            a
              font-size 1.6rem
              &::before
                width 24px
                height 18px
                background-size 24px 18px
                vertical-align -3px
              &::after
                display none
    .spNav
      display none
      width 100%
      background themeDarkGray01
      color themeWhite
      position absolute
      z-index 2
      bottom 0
      left 0
      transform translateY(100%)
      .spEntry
        padding 30px 10px
        background #5d5d5f
        a
          display block
      .spNav01
        li
          display block
          font-weight bold
          transition .4s
          .circle
            display: none
          a,.toggleBtn
            cursor pointer
            display block
            padding 15px 10px
            color themeWhite
            font-size 1.6rem
            line-height 1.4
            text-decoration none
            border-bottom 1px solid themeWhite
            position relative
            z-index 1
          a:not(.toggleBtn)
            &:after
              width 8px
              height 16px
              display block
              content ""
              position absolute
              right 10px
              top 50%
              transform translateY(-50%)
              background url(image_path + "/common/arrow_left02.png") center center no-repeat
              background-size contain
          .toggleBtn
            &:before, &:after
              width 12px
              height 2px
              display block
              content ""
              background themeMainColor01
              position absolute
              z-index 2
              right 10px
              top 50%
              transform translateY(-50%) rotate(0deg)
            &:before
              transform translateY(-50%) rotate(90deg)
              transition .4s
            &.on
              &:before
                transform translateY(-50%) rotate(0deg)
          &.on
            background rgba(0,0,0,0.3)
          .intNav01
            display none
            li
              a
                font-size 1.4rem
                padding 15px 20px
                font-weight normal
                position relative
                z-index 1
                &:after
                  width 8px
                  height 16px
                  display block
                  content ""
                  position absolute
                  right 10px
                  top 50%
                  transform translateY(-50%)
                  background url(image_path + "/common/arrow_left01.png") center center no-repeat
                  background-size contain
      .spTelInfo
        text-align center
        color themeWhite
        line-height 1.2
        padding 30px 15px
        .m_commonBtn01
          margin 10px 0 20px
          a
            font-size 1.8rem
            padding 15px
            box-shadow 0 3px 5px #4a4e4c
            +mediaSp()
              &::before
                width 26px
                height 20px
                vertical-align -3px
                background-image url(image_path + "/common/icon_mail_sp.png")
                background-size 26px 20px
        .telText
          font-size 1.6rem
          padding-bottom 10px
        .tel
          font-size 3rem
          display block
          padding-bottom 5px
          img
            margin-right 8px
            vertical-align -5px
          a
            color themeWhite
            font-weight normal
            display inline-block
            margin 0 auto
            text-decoration none
            border-bottom 1px solid themeWhite
        .time
          font-size 1.4rem
          display block
        .web
          display inline-block
          margin-top 20px
          padding-left 20px
          background url(image_path + "/common/ico_web_white.svg") left center no-repeat
          background-size 15px auto
          a
            display inline-block
            color themeWhite
      .spNav02
        display flex
        flex-wrap wrap
        justify-content space-between
        align-items stretch
        li
          width 50%
          display block
          font-weight bold
          border-bottom 1px solid themeWhite
          &:nth-child(2n)
            border-left 1px solid themeWhite
          a
            display block
            padding 10px 20px 10px 10px
            font-size 1.4rem
            text-decoration none
            color themeWhite
            font-weight normal
            position relative
            z-index 1
            &:after
              width 4px
              height 8px
              display block
              content ""
              position absolute
              right 10px
              top 50%
              transform translateY(-50%)
              background url(image_path + "/common/arrow_left01.png") center center no-repeat
              background-size contain
      .spCloseBtn
        display block
        padding 18px
        background #959596
        color themeWhite
        font-size 1.4rem
        font-weight bold
        text-align center
        cursor pointer
