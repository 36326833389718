/* ==========================================================================*/
/* layout */
/* ==========================================================================*/
.l_contents
  z-index 1
  position relative

.l_commonWrap01
  width 100%
  +mediaPc()
    min-width 1040px
.l_commonWrap02
  width 100%
  +mediaPc()
    min-width 1080px
.l_commonWrap03
  width 100%
  +mediaPc()
    min-width 1080px
    
.l_commonInner01
  width 100%
  margin-left auto
  margin-right auto
  +mediaPc()
    max-width 1040px
  +mediaSp()
    box-sizing border-box
    padding-left 15px
    padding-right 15px
    &--sp100
      padding-left 0
      padding-right 0

.l_commonInner02
  width 100%
  margin-left auto
  margin-right auto
  +mediaPc()
    max-width 1080px
  +mediaSp()
    box-sizing border-box
    padding-left 15px
    padding-right 15px

.l_commonInner03
  width 100%
  margin-left auto
  margin-right auto
  +mediaPc()
    max-width 1080px
  +mediaSp()
    box-sizing border-box
    padding-left 15px
    padding-right 15px


.l_commonMargin01
  margin-left auto
  margin-right auto

/* ==========================================================================*/
/* margin padding system */
/* ==========================================================================*/

for i in 0..10
  .mt{i*5}
    margin-top 5px * i
  .mb{i*5}
    margin-bottom 5px * i
  .pt{i*5}
    padding-top 5px * i
  .pb{i*5}
    padding-bottom 5px*i

.mb30-0
  mb(30px, 0)
.mt30-15
  mt-h(30px)
.pt30-15
  pt-h(30px)
.mb30-15
  mb-h(30px)
.pb30-15
  pb-h(30px)


.mt40-20
  mt-h(40px)
.pt40-20
  pt-h(40px)
.mb40-20
  mb-h(40px)
.pb40-20
  pb-h(40px)
.mb40-0
  mb(40px, 0px)


.pt60-20
  pt(60px, 20px)
.mt60-30
  mt-h(60px)
.mb60-30
  mb-h(60px)
.pt60-30
  pt-h(60px)
.pb60-30
  pb-h(60px)

.mt70-35
  mt-h(70px)
.mb70-35
  mb-h(70px)
.pt70-35
  pt-h(70px)
.pb70-35
  pb-h(70px)


.mt80-40
  mt-h(80px)
.mb80-40
  mb-h(80px)
.pt80-40
  pt-h(80px)
.pb80-40
  pb-h(80px)


.pt90-45
  pt-h(90px)


.mt100-50
  mt-h(100px)
.mb100-50
  mb-h(100px)
.pt100-50
  pt-h(100px)
.pb100-50
  pb-h(100px)

.mt120-60
  mt-h(120px)
.mb120-60
  mb-h(120px)
.pt120-60
  pt-h(120px)
.pb120-60
  pb-h(120px)
  
  
.mb140-70
  mb-h(140px)
.pb140-70
  pb-h(140px)
