// 頻繁に使うテキスト要素を記述（キャプションや1行リードテキストなど）
// 設置個所によりレイアウトが異なる場合は、ページ下部のテキストリンクレイアウトに調整記述を書くこと

// ==========================================================================
// テキストデザイン
// ==========================================================================
.m_contentsOverview
  text-align center
  font-size 1.8rem
  color themeDarkGray01
  +mediaSp()
    text-align left
ul.tag
  li
    display inline-block
    line-height 1.2
    margin 0 10px 15px 0
    padding 5px
    font-weight bold
    font-size 1.4rem
    +mediaSp()
      margin-bottom 10px
    &.support
      color tagSupport
      background rgba(tagSupport, 0.15)
    &.visitCare
      color tagVisitCare
      background rgba(tagVisitCare, 0.15)
    &.longDayService
      color tagLongDayService
      background rgba(tagLongDayService, 0.15)
    &.shortDayService
      color tagShortDayService
      background rgba(tagShortDayService, 0.15)
    &.communityCenter
      color tagCommunityCenter
      background rgba(tagCommunityCenter, 0.15)

.m_contentsLead
  text-align center
  font-size 1.6rem
  letter-spacing 0.1em
  color themeDarkGray01
  +mediaSp()
    text-align left
    font-size 1rem
  
  
  
  
      

// ==========================================================================
// テキストレイアウト
// ==========================================================================
