// ==========================================================================
// パンくずデザイン
// ==========================================================================
.m_breadCrumb
  background themeLightGray01
  font-size 1.4rem
  +mediaSp()
    display none
  ol
    padding 18px 0
    li
      display inline-block
      &:not(:last-child)
        margin 0 10px 0 0
        &::after
          content ""
          display inline-block
          width 5px
          height 9px
          background url(image_path + '/common/arrow_left02.png') left top no-repeat
          background-size 5px 9px
          vertical-align middle
          margin-left 10px
      a
        display inline-block
        color themeDarkGray01
        vertical-align middle
