.m_mvSlider
  overflow hidden
  +media(1080px)
    width 1080px
  +mediaSp()
    width 100%
  .sliderWrap
    +mediaPc()
      width 300%
      max-width: 3840px
      transform translateX(-50%)
      left 50%
      position relative
    ul
      clearfix()
      li
        img
          width auto
          max-width 100%
    > .js_slider
      li
        + li
          display none
  .bx-viewport
    overflow visible !important
  .bx-controls
    .bx-pager
    .bx-controls-direction
      display none
    .bx-pager
      +mediaSp()
        display block
        padding 15px 0
        text-align center
        font-size 0
        .bx-pager-item
          display inline-block
          margin 0 5px
          a
            display block
            background #e4ece8
            width 11px
            height 11px
            text-indent -9999px
            border-radius 50%
            box-shadow inset 0px 0px 4px 0px rgba(0, 0, 0, 0.35)
            &.active
              background themeMainColor01
              box-shadow none
    .bx-controls-direction
      +mediaPc()
        display block
        a
          display block
          width 33px
          height 49px
          text-indent -9999px
          position absolute
          top 0
          bottom 0
          margin auto
          z-index 100
          &.bx-next
            background url(image_path + "/top/slider_arrow_right.png") center center no-repeat
            background-size 100%
            right 34.2%
          &.bx-prev
            background url(image_path + "/top/slider_arrow_left.png") center center no-repeat
            background-size 100%
            left 34.2%


.m_topBlock01
  border-bottom solid 1px line01
  +mediaSp()
    border-top solid 1px line01
  .inBox
    padding 60px 0 150px
    position relative
    +mediaSp()
      padding 25px 15px 70px
    &.beginner
      background urL(image_path + "/top/ixon_beginner_green.png") center 45px no-repeat
      +mediaSp()
        background-size 42px
        background-position center 15px
    &.reason
      background urL(image_path + "/top/icon_medal_green.png") center 40px no-repeat
      +mediaPc()
        padding-left 30px
        .m_commonBtn02
          left 30px
      +mediaSp()
        background-size 56px
        background-position center 12px
    .m_subTitle01
      margin 0 0 10px
      +mediaSp()
        margin 0 0 15px
    .m_commonBtn02
      position absolute
      bottom 60px
      left 0
      right 0
      margin auto
      +mediaSp()
        padding 0 15px
        bottom 20px

.m_topBlock02
  .m_contentTitle01
    +mediaPc()
      border-bottom solid 1px line01
    +mediaSp()
      background none
  .inBox
    padding 55px 35px 255px
    position relative
    +mediaSp()
      padding 0 15px 55px
      position static
      .header
        display flex
        margin 0 -15px 20px
        &.imgLeft
          flex-flow row-reverse wrap;
        .title
          background themeLightGray01
          width 50%
          position relative
          .m_subTitle01
            height 122px
            font-size 1.8rem
            position absolute
            top 0
            bottom 0
            left 0
            right 0
            margin auto
        .img
          width 50%
          margin 0
          img
            width 100%
    .m_subTitle01
      margin 0 0 20px
    .img
      margin 0 0 50px
    p
      color themeDarkGray01
      span.caption
        font-size 1.4rem
        +mediaSp()
          font-size 1.2rem
    .btnArea
      +mediaPc()
        position absolute
        left 0
        right 0
        bottom 80px
        margin auto
      +mediaSp()
        margin 20px 0 0
        clearfix()
        .m_commonBtn02:nth-child(odd)
          width: 50%;
          padding-right 15px
          float left
        .m_commonBtn02:nth-child(even)
          width: 50%;
          margin 0
          padding-left  15px
          float right

.m_topBlock03
  .l_commonInner01
    padding-bottom 85px
    +mediaSp()
      padding-bottom 30px
