/* mixin */
//カーニング自動調整
kerning()
  -webkit-font-feature-settings "pkna"
  font-feature-settings "pkna"
  letter-spacing 0.03em

//メディアクエリ
mediaSp()
  @media only screen and (max-width breakPoint)
    {block}
mediaPc()
  @media only screen and (min-width breakPoint + 1)
    {block}
media(responsive)
  @media only screen and (max-width responsive)
    {block}

//フォントずれIEハック
ieHeight(ieHeight)
  @media all and (-ms-high-contrast: none)
    line-height ieHeight
    *::-ms-backdrop
      line-height ieHeight
  &:not(:target)
    line-height ieHeight + \9

// clearfix
clearfix()
  &:after
    content "."
    display block
    clear both
    height 0
    visibility hidden

//gradient
gradient(gradient1, gradientStart, gradient2, gradientEnd)
  background linear-gradient(to bottom, gradient1 gradientStart, gradient2 gradientEnd)

//フォントファミリー
min()
  font-family fontset-mincho
gothic()
  font-family fontset-gothic

// レスポンシブの縦余白指定
mt-h(mth)
  margin-top mth
  +mediaSp()
    margin-top mth * 0.5
pt-h(pth)
  padding-top pth
  +mediaSp()
    padding-top pth * 0.5
mb-h(mbh)
  margin-bottom mbh
  +mediaSp()
    margin-bottom mbh * 0.5
pb-h(pbh)
  padding-bottom pbh
  +mediaSp()
    padding-bottom pbh * 0.5
