// 頻繁に使うタブ形状の要素を記述
.m_headTab01
  border-bottom solid 1px #ccc
  +mediaSp()
    .l_commonInner02
      padding 0
  ul
    margin 0 auto
    clearfix()
    li
      display table
      float left
      width 25%
      border-left solid 1px #ccc
      +mediaPc()
        &:last-child
          border-right solid 1px #ccc
      +mediaSp()
        &:first-child
          border-left none
      a
        display table-cell
        background #f7f7f7
        height 115px
        line-height 1.4
        text-align center
        text-decoration none
        font-size 2.4rem
        vertical-align middle
        box-sizing border-box
        border-top solid 6px #ccc
        position relative
        +mediaSp()
          height 58px
          font-size 1.2rem
          font-weight bold
          border-top-width 3px
        span
          display block
          letter-spacing 0.01em
          font-size 1.6rem
          +mediaSp()
            font-size 0.8rem
        h1
          +mediaPc()
            font-weight normal
      &:hover
      &.current
        a
          background #fff
          border-top-color #f36c2a
      &.current
        a
          &:after
            content ""
            display block
            background #fff
            width 100%
            height 1px
            position absolute
            bottom -1px
            left 0
  &--bottom
    border-top solid 1px #ccc
    border-bottom 0
    ul
      li
        a
          border-top 0
          border-bottom solid 6px #ccc
        &:hover
        &.current
          a
            border-bottom-color #f36c2a
        &.current
          a
            &:after
              bottom auto
              top -1px
  &--two
    ul
      li
        width 50%
  &--three
    ul
      li
        +mediaPc()
          width 33%
          width calc(99.9% / 3)
        +mediaSp()
          width calc(100% / 3)
